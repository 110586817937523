<template>
  <div>
    <div class="columns mt-5">
      <div class="column is-4">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.name') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="financial.account_holder"
              class="input"
              :class="{ 'is-danger': required('account_holder') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.name')}...`"
            />
          </div>
        </div>
      </div>

      <div class="column is-4">
        <div class="field">
          <label class="label">{{ $t('message.fields.financial.iban') }} <span class="is_blue">*</span></label>
          <div class="control pr-2">
            <input
              v-model="financial.iban"
              class="input"
              :class="{ 'is-danger': required('iban') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.iban')}...`"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.financial.bank') }} <span class="is_blue">*</span></label>
          <div class="control pr-2">
            <input
              v-model="financial.account_bank"
              class="input"
              :class="{ 'is-danger': required('account_bank') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.bank')}...`"
            />
          </div>
        </div>
      </div>

      <div v-if="financial.iban && financial.iban.length >= 2 && !financial.iban.toLowerCase().startsWith('nl')" class="column is-2">
        <div class="field">
          <label class="label">{{ $t('message.fields.financial.bic') }}  <span class="is_blue">*</span></label>
          <div class="control pr-2">
            <input
              v-model="financial.bic"
              class="input"
              type="text"
              :placeholder="`${$t('message.fields.financial.bic')}...`"
            />
          </div>
        </div>
      </div>
    </div>
    
    <div class="columns" v-if="!alreadySigned">
      <div class="column is-6">
        <div class="field pr-2">
          <label class="label"
            >{{ $t('message.fields.financial.card') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <file-upload 
              :value="financial.card" 
              customUrl="https://werken-zoomerjobs.nl/api/media/image/guest"
              :customParams="{ hash, checksum }"
              :preventDeleteFromServer="true"
              :isMedia="true"
            />
          </div>
        </div>
      </div>
    </div>
    <p v-if="dataChecker">
      <strong>{{ $t('message.fields.financial.data_checker', { appClient: $appClient }) }}</strong>
    </p>
    <template v-else>
      <div class="columns">
        <div class="column">
          <div class="field mb-5">
            <label class="label"
              >{{ $t('message.fields.financial.type') }} <span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr_responsive">
                <input
                  v-model="financial.document_type"
                  value="Nationaal Paspoort"
                  type="radio"
                  name="document_type"
                  :class="{ 'is-danger': required('document_type') }"
                />
                <i class="m-2" :class="{ is_red: required('document_type') }"
                  >{{ $t('message.fields.financial.national') }}</i
                >
              </label>
              <label class="radio mr_responsive">
                <input
                  v-model="financial.document_type"
                  value="NL id-kaart"
                  type="radio"
                  name="document_type"
                  :class="{ 'is-danger': required('document_type') }"
                />
                <i class="m-2" :class="{ is_red: required('document_type') }"
                  >{{ $t('message.fields.financial.nl') }}</i
                >
              </label>
              <label class="radio mr_responsive">
                <input
                  v-model="financial.document_type"
                  value="EU id-kaart"
                  type="radio"
                  name="document_type"
                  :class="{ 'is-danger': required('document_type') }"
                />
                <i class="m-2" :class="{ is_red: required('document_type') }"
                  >{{ $t('message.fields.financial.eu') }}</i
                >
              </label>
              <label
                class="radio mr_responsive"
                :class="{ 'is-danger': required('document_type') }"
              >
                <input
                  v-model="financial.document_type"
                  value="Vreemdelingen document"
                  type="radio"
                  name="document_type"
                  :class="{ 'is-danger': required('document_type') }"
                />
                <i class="m-2" :class="{ is_red: required('document_type') }"
                  >{{ $t('message.fields.financial.alien') }}</i
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.financial.document_number') }} <span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <input
                v-model="financial.document_number"
                class="input"
                :class="{ 'is-danger': required('document_number') }"
                type="text"
                :placeholder="`${$t('message.fields.financial.document_number')}...`"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.financial.expiration_date') }} <span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <b-field>
                <b-datepicker
                  v-model="financial.document_expiration_date"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  :class="{ 'is-danger': required('document_expiration_date') }"
                  placeholder="dd/mm/jjjj"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!alreadySigned">
        <div class="columns">
          <div class="column">
            <label class="label">{{ $t('message.fields.financial.front') }} <span class="is_blue">*</span></label>
            <file-upload 
              :value="financial.document_front_hash" 
              customUrl="https://werken-zoomerjobs.nl/api/media/image/guest" 
              :customParams="{ hash, checksum }"
              :preventDeleteFromServer="true"
              :isMedia="true"
            />
          </div>

          <div class="column">
            <label class="label">{{ $t('message.fields.financial.back') }} <span class="is_blue">*</span></label>
            <file-upload 
              :value="financial.document_back_hash" 
              customUrl="https://werken-zoomerjobs.nl/api/media/image/guest" 
              :customParams="{ hash, checksum }"
              :preventDeleteFromServer="true"
              :isMedia="true"
            />
          </div>
        </div>

        <div class="columns" v-if="personal.nationality !== 'NL'">
          <div class="column is-6">
            <label class="label">{{ $t('message.fields.financial.bsn_document') }} <span class="is_blue">*</span></label>
            <file-upload 
              :value="financial.bsn_document_hash" 
              customUrl="https://werken-zoomerjobs.nl/api/media/image/guest" 
              :customParams="{ hash, checksum }"
              :preventDeleteFromServer="true"
              :isMedia="true"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import FileUpload from '../components/FileUpload.vue';

export default {
  components: { FileUpload },
  props: ["financial", "personal", "checked", "hash", "checksum", "dataChecker", "alreadySigned"],
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined, // Browser locale
    };
  },
  methods: {
    required: function() {
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mr_responsive {
  margin-right: 5%;
}
</style>
